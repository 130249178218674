// This is the one place where we are allowed to use the <a> tag
/* eslint-disable react/forbid-elements */
import { logEvent } from '@red-digital/bricks';
import React, { AnchorHTMLAttributes, ReactElement } from 'react';

interface LinkWrapperProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  disableUrlParamPersist?: boolean;
  newRelicActionName?: 'ctaSignupButton' | string;
  segmentTracking: {
    elementName: string;
    elementPosition: string;
    elementType?: string;
  } | false;
}

/**
 * @category Helper
 * @interface {LinkWrapperProps}
 *
 * @param {boolean} disableUrlParamPersist - optionally opt out of appending url params (like rv_click_id) to the href.
 * @param {string} newRelicActionName - if defined, a new relic page action will be fired onClick
 *
 * @param {string} elementName - used in the segment event fired
 * @param {string} elementPosition - used in the segment event fired
 * @param {string} elementType - defaults to 'LINK'
 */

const LinkWrapper = ({
  href, disableUrlParamPersist = false, newRelicActionName, segmentTracking, children, onClick, ...rest
}: LinkWrapperProps): ReactElement<HTMLAnchorElement> => {
  const appendProps = {} as { [key: string]: string };

  if (disableUrlParamPersist) {
    appendProps['data-disable-param-persist'] = 'true';
  }

  if (segmentTracking) {
    const { elementName, elementPosition, elementType } = segmentTracking;

    appendProps['data-element-name'] = elementName ?? '';
    appendProps['data-element-position'] = elementPosition ?? '';
    appendProps['data-element-type'] = elementType ?? 'LINK';
  }

  return (
    <a
      href={href}
      onClick={(e) => {
        if (onClick) onClick(e);
        if (newRelicActionName) logEvent(newRelicActionName, { rvClickIDURL: e.currentTarget.href });
      }}
      {...appendProps}
      {...rest}
    >
      {children}
    </a>
  );
};

export default LinkWrapper;
