import type { AnchorHTMLAttributes, FunctionComponent } from 'react';
import styles from './CTAButton.module.scss';
import LinkWrapper from '../LinkWrapper/linkWrapper';

/**
 * CTA Buttons are just links, yo
 * @category CTAButton
 * @extends AnchorHTMLAttributes<HTMLAnchorElement>
 * */

// TODO: update variants to support sign-up-flow-iframe when we are making site wide update

export type CTAColorVariant =
  | 'primary-blue'
  | 'primary-yellow'
  | 'spark-yellow'
  | 'primary-black'
  | 'secondary'
  | 'text-link'
  | 'aa-primary-blue'
  | 'aa-secondary'
  | 'aa-text-link';
export type CTAElementType =
  | 'LINK'
  | 'SIGN_UP_CTA_PRIMARY'
  | 'SIGN_UP_CTA_SECONDARY';

const wallyWeekSignup = {
  production:
    'https://www.walmart.com/plus/plans?code=eventoffers&programName=discountedmembership&programId=discmemb23',
  nonprod:
    'https://www-teflon.walmart.com/plus/plans?code=eventoffers&programName=discountedMembership&programId=discmemb23',
};

const regularSignup = {
  production: 'https://www.walmart.com/plus/plans',
  nonprod: 'https://www-teflon.walmart.com/plus/plans',
};

const urlSet = process.env.week === 'Walmart' ? wallyWeekSignup : regularSignup;
const baseSignupURL =
  process.env.environment === 'production' ? urlSet.production : urlSet.nonprod;

interface CTAButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  /**
   * The CTA button variants outlined in [CTA Buttons](https://www.figma.com/file/1DJl0hmx7bqeZRv9wY5Bjc/Walmart%2B-Components-(Copy)?node-id=806%3A805&t=W0jhMD4vVNsEJoYN-0) and [Text Link](https://www.figma.com/file/C3voe3hFqHokWRJlWosZtX/Walmart%2B-Core?node-id=82%3A682&t=Gbi1oeZshJt9NMFk-0)
   *
   * @inner
   */
  variant: CTAColorVariant;

  /**
   * Possible sizes
   * @inner
   */
  size?: 'default' | 'thin' | 'aa';

  /**
   * For tracking, the location of this CTA in the DOM. For example, 'navbar'
   * @inner
   */
  elementPosition: string;

  /**
   * For tracking, the element name for this CTA. For example, 'navbar'
   * @inner
   */
  elementName?: string;

  /**
   * For tracking, the element type for this CTA. Defaults to 'LINK'
   * @inner
   */
  elementType?: CTAElementType;

  /**
   * We normally pass all incoming URL params to Walmart. If we need to prevent that from happening, set this to true
   * @inner
   */
  excludeFromTracking?: boolean;

  /**
   * Optionally enter in tab index value to comply with a11y
   * @inner
   */
  tabIndex?: number;
}

/**
 * Renders an `a` HTML element. If no `children` exist, the default child will be **Claim your free trial** on all screen sizes.
 *
 * If no `href` is provided, we assume that this is a signup link and auto-format the URL
 *
 * @category CTAButton
 * @subcategory Components
 *
 * @param {CTAButtonProps} props
 *
 * @example
 * // Renders the default CTA: <a> with variant 'primary-blue' and default size
 * <CTAButton />
 *
 * @example
 * // Renders <a> with variant 'secondary', thin size, and custom HREF and copy
 * <CTAButton variant='secondary' size='thin' href='https://example.com'>A CTA</CTAButton>
 */
const CTAButton: FunctionComponent<CTAButtonProps> = ({
  variant = 'primary-blue',
  size = 'default',
  href,
  children,
  className,
  elementPosition,
  elementName,
  elementType,
  excludeFromTracking = false,
  tabIndex = 0,
  ...props
}: CTAButtonProps) => {
  // checks to ensure button variant we get is a valid type, defaults to primary-blue if it is not valid
  const possibleButtonVariants = [
    'primary-blue',
    'primary-black',
    'primary-yellow',
    'spark-yellow',
    'secondary',
    'text-link',
    'aa-primary-blue',
    'aa-secondary',
    'aa-text-link',
  ];
  const isValid = possibleButtonVariants.some((button) =>
    variant.includes(button)
  );

  if (!isValid) {
    variant = 'primary-blue';
  }

  const segmentTracking = {
    elementName: elementName || 'CTA',
    elementPosition,
    elementType,
  };

  return (
    <LinkWrapper
      segmentTracking={segmentTracking}
      href={href || baseSignupURL}
      className={[
        styles[`cta-${variant}`],
        styles[
          `${variant.includes('text-link') ? 'text-link' : 'cta'}-size-${size}`
        ],
        className,
      ].join(' ')}
      {...props}
      disableUrlParamPersist={excludeFromTracking}
      newRelicActionName='ctaSignupButton'
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }
      }}
      tabIndex={tabIndex}
    >
      {children || (
        <>
          Claim your <span className={styles.ctaExpandedCopy}>free</span> trial
        </>
      )}
    </LinkWrapper>
  );
};

export default CTAButton;
